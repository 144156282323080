import { ProviderDto, ProviderWithTimeSlotsDto } from "core/services/booking";
import { DateTime } from "luxon";

const providerOnlineStatusOrder: { [key: string]: number } = {
  YES: 0,
  CALL: 1,
  NO: 2
};

const providerSortOnProviderOnlineStatus = (a: ProviderDto, b: ProviderDto) => {
  if (!a.providerOnlineStatus || !b.providerOnlineStatus) return -1;
  return (
    providerOnlineStatusOrder[a.providerOnlineStatus] -
    providerOnlineStatusOrder[b.providerOnlineStatus]
  );
};

// Sort providers for the TimeSlotSelector
export function sortProvidersForTimeSlotSelector(
  providers: Array<ProviderWithTimeSlotsDto>
) {
  if (providers === undefined || null) {
    return null;
  }

  return (
    providers
      // Sort by first name then last name
      .sort((a, b) => (a.firstName ?? "").localeCompare(b.firstName ?? ""))
      .sort((a, b) => (a.lastName ?? "").localeCompare(b.lastName ?? ""))

      // Sort by earliest timeslot
      .sort((a, b) =>
        a.timeSlots?.length > 0 &&
        b.timeSlots?.length > 0 &&
        a.timeSlots[0].startTime.valueOf() -
          b.timeSlots[0].startTime.valueOf() <
          0
          ? -1
          : 0
      )

      // Sort favourites to the top
      .sort((a, b) => Number(b.isFavorite) - Number(a.isFavorite))

      // Sort online bookable providers before call clinic providers
      .sort(
        (a, b) =>
          providerOnlineStatusOrder[a.providerOnlineStatus!] -
          providerOnlineStatusOrder[b.providerOnlineStatus!]
      )
  );
}

// Sort providers for the Practice Team page
export function sortProvidersForPracticeTeam(providers: Array<ProviderDto>) {
  if (providers === undefined || null) {
    return null;
  }

  return Array.from(providers)
    .sort((a, b) => (a.firstName ?? "").localeCompare(b.firstName ?? ""))
    .sort((a, b) => (a.lastName ?? "").localeCompare(b.lastName ?? ""))
    .sort((a, b) => providerSortOnProviderOnlineStatus(a, b));
}

export function getCountry() {
  const timezones = {
    "Australia/ACT": "AU",
    "Australia/Adelaide": "AU",
    "Australia/Brisbane": "AU",
    "Australia/Broken_Hill": "AU",
    "Australia/Canberra": "AU",
    "Australia/Currie": "AU",
    "Australia/Darwin": "AU",
    "Australia/Eucla": "AU",
    "Australia/Hobart": "AU",
    "Australia/LHI": "AU",
    "Australia/Lindeman": "AU",
    "Australia/Lord_Howe": "AU",
    "Australia/Melbourne": "AU",
    "Australia/NSW": "AU",
    "Australia/North": "AU",
    "Australia/Perth": "AU",
    "Australia/Queensland": "AU",
    "Australia/South": "AU",
    "Australia/Sydney": "AU",
    "Australia/Tasmania": "AU",
    "Australia/Victoria": "AU",
    "Australia/West": "AU",
    "Australia/Yancowinna": "AU",
    NZ: "NZ",
    "NZ-CHAT": "NZ",
    "Pacific/Chatham": "NZ",
    "Pacific/Auckland": "NZ"
  };

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (timezone === "" || !timezone) {
    return null;
  }

  return timezones[timezone];
}
export function getSortedDates(dates: string[]) {
  return dates
    .map(as => DateTime.fromISO(as))
    .sort((left, right) => left.valueOf() - right.valueOf());
}
